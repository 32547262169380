import { IncomingMessage } from 'http';

import { fetchStocksForCategories } from './stocks';
import { fechFromBPartsApi } from './utils';
import useSortedPerAvailabilityList from '../hooks/use-sorted-per-availability-list';

export const fetchGroups = async (
  {
    locale = 'en',
    reference,
    bp_reference,
    min_price,
    max_price,
    car_identifier,
  }: {
    locale?: string;
    reference?: string;
    bp_reference?: string;
    min_price?: string;
    max_price?: string;
    car_identifier?: string;
  },
  req?: IncomingMessage
): Promise<TCategories> => {
  const query = {
    ...(reference && { reference }),
    ...(bp_reference && { bp_reference }),
    ...(min_price && { min_price }),
    ...(max_price && { max_price }),
    ...(car_identifier && { car_identifier }),
  };

  const url = `/${locale}/shop/groups?vehicle_stock=false&group=true&no_round=true${Object.entries(
    query
  ).reduce((final, [key, value]) => `${final}&${key}=${value}`, '')}`;

  return fechFromBPartsApi(
    url,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );
};

export const fetchGroupsWithStocks = async (
  {
    locale = 'en',
    reference,
    bp_reference,
    min_price,
    max_price,
    car_identifier,
    shipping_location,
  }: {
    locale?: string;
    reference?: string;
    bp_reference?: string;
    min_price?: string;
    max_price?: string;
    car_identifier?: string;
    shipping_location?: string;
  },
  req?: IncomingMessage
): Promise<TCategories> => {
  const query = {
    ...(reference && { reference }),
    ...(bp_reference && { bp_reference }),
    ...(min_price && { min_price }),
    ...(max_price && { max_price }),
    ...(car_identifier && { car_identifier }),
    ...(shipping_location && { shipping_location }),
  };

  const url = `/${locale}/shop/catalogue?vehicle_stock=false&group=true&no_round=true${Object.entries(
    query
  ).reduce((final, [key, value]) => `${final}&${key}=${value}`, '')}`;

  return fechFromBPartsApi(
    url,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );
};

/*export const fetchGroupsWithStocks = async (
  {
    locale,
    vehicle,
  }: {
    locale: string;
    vehicle?: string;
  },
  req?: IncomingMessage
) => {
  const groups: Array<TCategory> = await fetchGroups({ locale }, req);

  const stocks = await fetchStocksForCategories(
    {
      locale,
      categories: groups.map((g: TCategory) => g.slug),
      vehicle,
      no_round: true,
    },
    req
  );

  const groupsWithStocks = groups.map((g: TCategory) => {
    g.total_products = stocks
      .filter((s) => s.category_identifier === g.slug)
      .shift()?.stock;

    return g;
  });

  return groupsWithStocks;
};*/

// https://dev.b-parts.com/de/shop/groups_parts_by_slug?part_identifier=stossstangentraeger-vorne&group_identifier=karosserie
export const checkGroupsSlugs = async (
  {
    locale,
    group_identifier,
    part_identifier,
  }: {
    locale: string;
    group_identifier: string;
    part_identifier?: string;
  },
  req?: IncomingMessage
): Promise<void> => {
  const query = {
    group_identifier,
    ...(part_identifier && { part_identifier }),
  };

  const url = `/${locale}/shop/groups_parts_by_slug?${Object.entries(
    query
  ).reduce(
    (final, [key, value]) => `${final}${final ? '&' : ''}${key}=${value}`,
    ''
  )}`;

  return fechFromBPartsApi(
    url,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );
};
